@charset "UTF-8";

.plande_main_box1 {
    background-image: url("#{$base_path}plan_detail/main.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @media #{$size_1000} {
        background-position: center bottom;
        height: 180px;
    }
    @media #{$size_500} {
        height: 150px;
    }
}
/*
.plande_main_box2 {
    background-color: #fff8f8;
    padding-top: 164px;
    padding-bottom: 150px;

    .wrap {
        max-width: 1430px;
        margin-left: auto;
        margin-right: auto;
        padding-left: 15px;
        padding-right: 15px;
        box-sizing: border-box;
        @media #{$size_1000} {
          padding-left: 0;
          padding-right: 0;
        }

        article {
            position: relative;
            background-color: #fff;
            padding-top: 150px;
            padding-bottom: 150px;
            margin-bottom: 80px;

            &:before {
                content: "Wedding Plan";
                display: inline-block;
                position: absolute;
                top: -110px;
                left: -5%;
                font-family: 'Tangerine', cursive;
                color: #eeb4b4;
                @include fontsize(150);
            }

            .article_wrap {
                max-width: 930px;
                padding-left: 15px;
                padding-right: 15px;
                margin-left: auto;
                margin-right: auto;
                box-sizing: border-box;
                @media #{$size_1000} {
                  padding-left: 0;
                  padding-right: 0;
                }
                header {
                    padding-bottom: 60px;
                    display: flex;
                    @media #{$size_1000} {
                      padding-left: 30px;
                      padding-right: 30px;
                      box-sizing: border-box;
                    }

                    .img_div {
                        width: 56.1111111111%;
                        margin-right: 3.33333333333%;

                        img {
                            width: 100%;
                            height: auto;
                        }
                    }

                    .header_div {
                        width: 40.5555555556%;
                        text-align: left;
                        color: #444444;

                        span {
                            display: inline-block;
                            @include font_yuu;
                            padding: 4px 32px;
                            color: #fff;
                            background-color: #ec9c9c;
                            margin-bottom: 18px;
                        }

                        h1 {
                            line-height: 1.6;
                            @include font_HiraginoProN;
                            padding-bottom: 10px;
                        }

                        .p1 {
                            @include font_HiraginoProN;
                            padding-bottom: 4px;
                        }

                        .p2 {
                            color: #000;
                            @include font_yuu;
                            padding-bottom: 8px;
                        }

                        .p3 {
                            line-height: 1.9;
                            color: #000;
                            @include font_yuu;
                        }
                    }
                }

                .plan_detail {
                    display: flex;
                    padding-bottom: 40px;

                    div {
                        width: 100%;
                        padding: 30px 30px 35px;
                        background-color: #fff8f8;
                        text-align: left;
                        box-sizing: border-box;

                        h3 {
                            @include font_HiraginoProN;
                            color: #444444;
                            padding-bottom: 12px;
                        }

                        p {
                            @include font_yuu;
                            color: #000;
                            line-height: 2.0;
                        }

                        &:nth-child(2) {
                            margin-right: 0;
                        }
                    }
                }

                .plan_naiyou {
                    margin-bottom: 40px;
                    @media #{$size_1000} {
                      padding-left: 30px;
                      padding-right: 30px;
                      box-sizing: border-box;
                    }
                    h3 {
                        color: #444444;
                        text-align: left;
                        @include font_HiraginoProN;
                        padding-bottom: 15px;
                    }

                    .table_div {
                        table {
                            width: 100%;
                            text-align: center;
                            @include font_yuu;
                            border-collapse: collapse;
                            @include fontsize(16);

                            td,
                            th {
                                border-bottom: 1px solid #fff;
                                border-right: 1px solid #fff;
                                padding-top: 12px;
                                padding-bottom: 12px;
                                box-sizing: border-box;
                            }

                            tbody {
                                color: #000;

                                tr {
                                    th{
                                      background-color: #f195a0;
                                      color:#fff;
                                      width: 30%;
                                    }
                                    td {
                                        background-color: #fff4f4;
                                        text-align: left;
                                        padding-left: 15px;
                                        padding-right: 15px;
                                        box-sizing: border-box;
                                        width: 70%;
                                    }
                                }
                            }
                        }
                    }
                }//footer

                footer {
                    @media #{$size_1000} {
                      padding-left: 30px;
                      padding-right: 30px;
                      box-sizing: border-box;
                    }
                    h3 {
                        color: #444444;
                        text-align: left;
                        @include font_HiraginoProN;
                        padding-bottom: 15px;
                    }

                    .table_div {
                        table {
                            width: 100%;
                            text-align: center;
                            @include font_yuu;
                            border-collapse: collapse;
                            @include fontsize(16);

                            td,
                            th {
                                border-bottom: 1px solid #fff;
                                border-right: 1px solid #fff;
                                padding-top: 12px;
                                padding-bottom: 12px;
                                box-sizing: border-box;
                            }

                            thead {
                                tr {
                                    th {
                                        background-color: #f195a0;
                                        color: #fff;
                                    }
                                }
                            }

                            tbody {
                                color: #000;
                                tr {
                                    td {
                                        background-color: #fff4f4;

                                        &:nth-child(2n) {
                                            background-color: #fdecec;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }//footer
            }
        }
    }
    @media #{$size_1600} {
        .wrap {
            article {
                &:before {
                    left: 0;
                }
            }
        }
    }
    @media #{$size_1000} {
        padding-top: 80px;
        padding-bottom: 80px;

        .wrap {
            article {
                padding-top: 80px;
                padding-bottom: 60px;
                margin-bottom: 60px;

                &:before {
                    top: -80px;
                    left: 2%;
                    @include fontsize(100);
                }

                .article_wrap {
                    header {
                        display: block;
                        padding-bottom: 40px;

                        .img_div {
                            //max-width: 500px;
                            width: 100%;
                            margin-right: auto;
                            margin-left: auto;
                            padding-bottom: 30px;
                        }

                        .header_div {
                            max-width: 500px;
                            width: 100%;
                            margin-right: auto;
                            margin-left: auto;
                        }
                    }

                    .plan_detail {
                        display: block;
                        padding-bottom: 30px;
                        max-width: 500px;
                        width: 100%;
                        margin-right: auto;
                        margin-left: auto;

                        div {
                            width: 100%;
                            margin-right: 0;
                            padding: 30px 20px 35px;

                            &:nth-child(1) {
                                margin-bottom: 20px;
                            }

                            &:nth-child(2) {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
        }
    }
    @media #{$size_500} {
        .wrap {
            article {
                &:before {
                    top: -65px;
                    left: 0;
                    @include fontsize(70);
                }

                .article_wrap {
                    footer {
                        .table_div {
                            overflow: auto;
                            white-space: nowrap;

                            &::-webkit-scrollbar {
                                height: 5px;
                            }

                            &::-webkit-scrollbar-track {
                                background: #F1F1F1;
                            }

                            &::-webkit-scrollbar-thumb {
                                background: #BCBCBC;
                            }

                            table {
                                @include fontsize(15);

                                td,
                                th {
                                    padding-left: 10px;
                                    padding-right: 10px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}*/















.plande_main_box2--02 {
    background-color: #f7f7f7;
    padding-top: 164px;
    padding-bottom: 150px;

    .wrap {
        max-width: 1430px;
        margin-left: auto;
        margin-right: auto;
        padding-left: 15px;
        padding-right: 15px;
        box-sizing: border-box;
        @media #{$size_1000} {
          padding-left: 0;
          padding-right: 0;
        }

        article {
            position: relative;
            background-color: #fff;
            padding-top: 150px;
            padding-bottom: 150px;
            margin-bottom: 80px;

            &:before {
                content: "Wedding Plan";
                display: inline-block;
                position: absolute;
                top: -110px;
                left: -5%;
                font-family: 'Tangerine', cursive;
                color: #cdcab1;
                @include fontsize(150);
            }

            .article_wrap {
                max-width: 930px;
                padding-left: 15px;
                padding-right: 15px;
                margin-left: auto;
                margin-right: auto;
                box-sizing: border-box;
                @media #{$size_1000} {
                  padding-left: 0;
                  padding-right: 0;
                }
                header {
                    padding-bottom: 60px;
                    display: flex;
                    @media #{$size_1000} {
                      padding-left: 30px;
                      padding-right: 30px;
                      box-sizing: border-box;
                    }

                    .img_div {
                        width: 56.1111111111%;
                        margin-right: 3.33333333333%;

                        img {
                            width: 100%;
                            height: auto;
                        }
                    }

                    .header_div {
                        width: 40.5555555556%;
                        text-align: left;
                        color: #444444;

                        span {
                            display: inline-block;
                            @include x03;
                            padding: 4px 32px;
                            color: #fff;
                            background-color: #aba786;
                            margin-bottom: 18px;
                        }

                        h1 {
                            line-height: 1.6;
                            padding-bottom: 10px;
                            @include x03;
                        }

                        .p1 {
                            padding-bottom: 4px;
                            @include x03;
                        }

                        .p2 {
                            color: #000;
                            @include x03;
                            padding-bottom: 8px;
                        }

                        .p3 {
                            line-height: 1.9;
                            color: #000;
                            @include x03;
                        }
                    }
                }

				.plan_link {
					          display: flex;
                    padding-bottom: 40px;
                    @media #{$size_1000}{
                      padding-bottom: 30px;
                      max-width: 500px;
                      width: 100%;
                      margin: 0 auto;
                    }



					 div {
                        width: 100%;
                        padding: 30px 30px 35px;
                        background-color: rgba(0, 0, 0, 0);
                        text-align: left;
                        box-sizing: border-box;
                        border: solid 1px #aba786;
                        @media #{$size_500}{
                            margin: 0 30px;
                        }


                        h3 {
                            @include font_HiraginoProN;
                            color: #aba786;
                            padding-bottom: 12px;
                            @include x03;
                        }
                        ul {
                          li{
                            margin-bottom: 16px;
                            a{
                              img {
                                margin: 0 3px 10px;
                              }
                              span {
                                border-bottom: solid 1px #333;
                                @include x03;
                                color: #000;
                                line-height: 2.0;
                              }
                            }
                          }

                        }

                        p {
                            @include x03;
                            color: #000;
                            line-height: 2.0;
                        }

                        &:nth-child(2) {
                            margin-right: 0;
                        }
					}
				}

                .plan_detail {
                    display: flex;
                    padding-bottom: 40px;

                    div {
                        width: 100%;
                        padding: 30px 30px 35px;
                        background-color: #e2e0cb;
                        text-align: left;
                        box-sizing: border-box;

                        h3 {
                            @include font_HiraginoProN;
                            color: #444444;
                            padding-bottom: 12px;
                            @include x03;
                        }

                        p {
                            @include x03;
                            color: #000;
                            line-height: 2.0;
                        }

                        &:nth-child(2) {
                            margin-right: 0;
                        }
                    }
                }

                .plan_naiyou {
                    margin-bottom: 40px;
                    @media #{$size_1000} {
                      padding-left: 30px;
                      padding-right: 30px;
                      box-sizing: border-box;
                    }
                    h3 {
                        color: #444444;
                        text-align: left;
                        @include font_HiraginoProN;
                        padding-bottom: 15px;
                        @include x03;
                    }

                    .table_div {
                        table {
                            width: 100%;
                            text-align: center;
                            @include x03;
                            border-collapse: collapse;
                            @include fontsize(16);

                            td,
                            th {
                                border-bottom: 1px solid #fff;
                                border-right: 1px solid #fff;
                                padding-top: 12px;
                                padding-bottom: 12px;
                                box-sizing: border-box;
                            }

                            tbody {
                                color: #000;

                                tr {
                                    th{
                                      background-color: #aba786;
                                      color:#fff;
                                      width: 30%;
                                    }
                                    td {
                                        background-color: #f7f7f7;
                                        text-align: left;
                                        padding-left: 15px;
                                        padding-right: 15px;
                                        box-sizing: border-box;
                                        width: 70%;
                                    }
                                }
                            }
                        }
                    }
                }//footer

                footer {
                    @media #{$size_1000} {
                      padding-left: 30px;
                      padding-right: 30px;
                      box-sizing: border-box;
                    }
                    h3 {
                        color: #444444;
                        text-align: left;
                        @include font_HiraginoProN;
                        padding-bottom: 15px;
                        @include x03;
                    }

                    .table_div {
                        table {
                            width: 100%;
                            text-align: center;
                            @include x03;
                            border-collapse: collapse;
                            @include fontsize(16);

                            td,
                            th {
                                border-bottom: 1px solid #fff;
                                border-right: 1px solid #fff;
                                padding-top: 12px;
                                padding-bottom: 12px;
                                box-sizing: border-box;
                            }

                            thead {
                                tr {
                                    th {
                                        background-color: #aba786;
                                        color: #fff;
                                    }
                                }
                            }

                            tbody {
                                color: #000;
                                tr {
                                    td {
                                        background-color: #f7f7f7;

                                        &:nth-child(2n) {
                                            background-color: #f1f1f1;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }//footer
            }
        }
    }
    @media #{$size_1600} {
        .wrap {
            article {
                &:before {
                    left: 0;
                }
            }
        }
    }
    @media #{$size_1000} {
        padding-top: 80px;
        padding-bottom: 80px;

        .wrap {
            article {
                padding-top: 80px;
                padding-bottom: 60px;
                margin-bottom: 60px;

                &:before {
                    top: -80px;
                    left: 2%;
                    @include fontsize(100);
                }

                .article_wrap {
                    header {
                        display: block;
                        padding-bottom: 40px;

                        .img_div {
                            //max-width: 500px;
                            width: 100%;
                            margin-right: auto;
                            margin-left: auto;
                            padding-bottom: 30px;
                        }

                        .header_div {
                            max-width: 500px;
                            width: 100%;
                            margin-right: auto;
                            margin-left: auto;
                        }
                    }

                    .plan_detail {
                        display: block;
                        padding-bottom: 30px;
                        max-width: 500px;
                        width: 100%;
                        margin-right: auto;
                        margin-left: auto;

                        div {
                            width: 100%;
                            margin-right: 0;
                            padding: 30px 20px 35px;

                            &:nth-child(1) {
                                margin-bottom: 20px;
                            }

                            &:nth-child(2) {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
        }
    }
    @media #{$size_500} {
        .wrap {
            article {
                &:before {
                    top: -65px;
                    left: 0;
                    @include fontsize(70);
                }

                .article_wrap {
                    footer {
                        .table_div {
                            overflow: auto;
                            white-space: nowrap;

                            &::-webkit-scrollbar {
                                height: 5px;
                            }

                            &::-webkit-scrollbar-track {
                                background: #F1F1F1;
                            }

                            &::-webkit-scrollbar-thumb {
                                background: #BCBCBC;
                            }

                            table {
                                @include fontsize(15);

                                td,
                                th {
                                    padding-left: 10px;
                                    padding-right: 10px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
