@charset "UTF-8";

.cere_box1 {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &::before {
        background-image: url("#{$base_path}ceremony/main.jpg");
    }
    .main_title {
        .title1 {
            @include fontsize(60);
            padding-bottom: 22px;
        }
    }
    @media #{$size_1000} {
        .main_title {
            .title1 {
                @include fontsize(48);
                padding-bottom: 20px;
            }
        }
    }
    @media #{$size_500} {
        .main_title {
            .title1 {
                @include fontsize(36);
                padding-bottom: 15px;
            }
        }
    }
}

.cere_box2 {
    padding-top: 156px;
    padding-bottom: 430px;

    .main {
        display: flex;

        .cere_slider {
            width: 57.2916666667%;
            margin-right: 5.20833333333%;

            .cere_slider_ul {
                li {
                    img {
                        width: 100%;
                        height: auto;
                    }
                }

                .slick-arrow {
                    background-color: transparent;
                    border: none;
                    cursor: pointer;
                    outline: none;
                    padding: 0;
                    appearance: none;
                    position: absolute;
                    bottom: -33px;
                    width: 66px;
                    height: 66px;
                    background-color: #e08a94;
                    z-index: 10;
                    text-indent: 100%;
                    white-space: nowrap;
                    overflow: hidden;
                    display: block;
                    background-repeat: no-repeat;
                    background-position: center;
                    background-size: 16px;
                    transition: 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);

                    &:hover {
                        background-color: #5d3035;
                        transition: 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
                    }
                }

                .slick-prev {
                    right: 100px;
                    background-image: url("#{$base_path}ceremony/arrow1.png");
                }

                .slick-next {
                    right: 33px;
                    background-image: url("#{$base_path}ceremony/arrow2.png");
                }
            }
        }

        .main_text {
            color: #000;
            width: 37.5%;
            display: flex;
            align-items: center;

            div {
                padding-right: 30px;
                box-sizing: border-box;
                text-align: left;
                max-width: 480px;

                h2 {
                    @include x01;
                    line-height: 1.8;
                    padding-bottom: 45px;

                }

                p {
                    @include x03;
                    line-height: 2.5;

                }
            }
        }
    }
    @media #{$size_1000} {
        padding-top: 0px;
        padding-bottom: 60px;

        .main {
            display: block;

            .cere_slider {
                width: 100%;
                margin-right: 0;

                .cere_slider_ul {
                    .slick-arrow {
                        bottom: -20px;
                        width: 40px;
                        height: 40px;
                        background-size: 10px;
                    }

                    .slick-prev {
                        right: 61px;
                    }

                    .slick-next {
                        right: 20px;
                    }
                }
            }

            .main_text {
                width: 100%;
                display: block;
                padding-top: 50px;

                div {
                    padding-right: 30px;
                    padding-left: 30px;
                    max-width: 100%;

                    h2 {
                        padding-bottom: 35px;
                        @media #{$size_1000} {
                          padding-bottom: 20px;
                        }
                    }
                }
            }
        }
    }
    @media #{$size_700} {
      padding-bottom: 60px;
    }
}

.cere_box3 {
    .main {
        background-color: #fff8f8;

        .wrap {
            padding-bottom: 460px;
            position: relative;

            &::after {
                display: block;
                content: "";
                clear: both;
            }

            .img {
                margin-top: -200px;
                max-width: 1320px;
                float: right;

                img {
                    width: 100%;
                    height: auto;
                }
            }

            article {
                position: absolute;
                bottom: 210px;
                left: 0;
                padding-left: 30px;
                padding-right: 30px;
                box-sizing: border-box;

                div {
                    background-color: #fff;
                    text-align: left;
                    color: #000;
                    max-width: 715px;
                    padding: 110px;
                    box-sizing: border-box;

                    h2 {
                        @include x01;
                        line-height: 1.8;
                        padding-bottom: 25px;
                    }

                    p {
                        @include x03;
                        line-height: 2.5;
                    }
                }
            }
        }
    }
    @media #{$size_1000} {
      padding-bottom: 0;
        .main {

            .wrap {
                padding-bottom: 100px;
                position: relative;
                padding-left: 0;
                padding-right: 0;
                padding-bottom: 0;
                .img {
                    margin-top: 0;
                    float: none;
                }

                article {
                    position: static;
                    float: none;
                    padding-left: 0;
                    padding-right: 0px;
                    margin-top: 0%;


                    div {
                        padding: 60px 30px;
                        box-sizing: border-box;
                        max-width: 100%;
                        padding-left: 30px;
                        padding-right: 30px;
                        box-sizing: content-box;


                        h2 {
                            padding-bottom: 20px;
                        }

                        p {
                            line-height: 2.5;
                        }
                    }
                }
            }
        }
    }
    @media #{$size_500} {
        .main {
            .wrap {
                padding-bottom: 80px;

                .img {

                }

                article {
                    padding-right: 0;

                    div {
                        padding: 40px 20px;
                        box-sizing: border-box;

                        h2 {
                            padding-bottom: 20px;
                        }

                        p {
                            line-height: 2.5;
                        }
                    }
                }
            }
        }
    }
}

.cere_box4 {
    background-image: url("#{$base_path}ceremony/bk2.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #fff;
    height: 1196px;
    padding-bottom: 460px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    article {
        max-width: 560px;
        padding-left: 30px;
        padding-right: 30px;
        box-sizing: border-box;
        color: #000;
        margin-bottom: -54px;

        h2 {
            @include x01;
            line-height: 1.8;
            padding-bottom: 45px;
        }

        p {
            text-align: left;
            @include x03;
            line-height: 2.5;
        }
    }

    @media #{$size_1000} {
        height: 800px;
        padding-bottom: 200px;
        article {
            margin-bottom: -104px;
            h2 {
                padding-bottom: 35px;
            }
            p{
              padding-bottom: 60px;
            }

        }
    }
    @media #{$size_500} {
        height: 600px;
        padding-bottom: 200px;
        article {
            margin-bottom: -204px;
            h2 {
                padding-bottom: 25px;
            }

        }
    }
}

.cere_box5 {
    .main {
        .wrap {
            padding-bottom: 400px;
            .img {
                max-width: 1000px;
            }
            article {
                div {
                    padding: 140px 100px;
                }
            }
        }
    }
    @media #{$size_1000} {
        .main {
            .wrap {
                padding-bottom: 100px;
                article {
                    div {
                        padding: 60px 30px;
                    }
                }
            }
        }
    }
    @media #{$size_500} {
        .main {
            .wrap {
                padding-bottom: 80px;
                article {
                    div {
                        padding: 40px 20px;
                    }
                }
            }
        }
    }
}

.cere_box6 {
    background-image: url("#{$base_path}ceremony/bk4.jpg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 2311px;
    article {
        padding-top: 180px;
        padding-left: 30px;
        padding-right: 30px;
        box-sizing: border-box;
        h2 {
            @include x01;
            line-height: 1.8;
            padding-bottom: 45px;
        }

        p {
            text-align: center;
            @include x03;
            line-height: 2.5;
        }
    }
    @media #{$size_1000} {
        padding-bottom: 700px;
        height: auto;
        background-size: 100%;
        background-position: bottom center;
        article {
            padding-top: 80px;
            h2 {
                padding-bottom: 35px;
            }
        }
    }
    @media #{$size_800} {
      padding-bottom: 600px;
    }
    @media #{$size_500} {
        background-position: bottom center;
        padding-bottom: 400px;
        article {
            padding-top: 60px;
            h2 {
                letter-spacing: -1px;
                padding-bottom: 25px;
            }
        }
    }
}

#thumbnail-list{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 10px;
  @media #{$size_1000} {
    display: inline-block;
    margin: 20px auto 0 auto;
  }
  @media #{$size_600} {
    display: block;
    margin-top: 20px;
    margin-left: 15px;
  }
  li{
    width: 100px;
    margin-left: 5px;
    margin-right: 5px;
    box-sizing: border-box;
    position: relative;
    z-index: 0;
    @media #{$size_1300} {
      width: 80px;
    }
    @media #{$size_1200} {
      width: 70px;
    }
    @media #{$size_1000} {
      width: 10px;
      height: 10px;
      border-radius: 5px;
      background-color: #ddd;
      float: left;
      overflow: hidden;
    }
    &:hover{
      cursor: pointer;
    }
    &:before{
      content:"";
      display: block;
      position: absolute;
      box-sizing: border-box;
      width: 100%;
      height: 100%;
      border:solid 4px #a8a58a;
      top: 0;
      left: 0;
      z-index: 1;
      opacity: 0;
      @media #{$size_1000} {
        border: none;
        background-color: #a8a58a;
      }
    }
    img{
      width: 100%;
      @media #{$size_1000} {
        display: none;
      }
    }
  }
  .thumbnail-current{
    &:before{
      opacity: 1;
    }

  }
}
