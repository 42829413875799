@charset "utf-8";

.fair_box1 {
    display: flex;
    align-items: center;

    &::before {
        background-image: url("#{$base_path}fair/main.jpg");
    }

    .wrap {
        width: 100%;
        display: flex;

        .title_wrap {
            width: 50%;
            color: #000;

            .title1 {
                @include lora;
                padding-bottom: 14px;
            }

            .title2 {
                @include x01;
                padding-bottom: 55px;
                font-weight: bold;

                span {
                    position: relative;
                    display: inline-block;

                    &::after,
                    &::before {
                        content: "";
                        display: block;
                        position: absolute;
                        top: 11px;
                        width: 44px;
                        height: 1px;
                        background-color: #c9c9c9;
                    }

                    &::before {
                        left: -59px;
                    }

                    &::after {
                        right: -59px;
                    }
                }
            }

            div {
                .p1 {
                    @include x03;
                    padding-bottom: 15px;
                }

                .span1 {
                    @include lora;
                    padding-bottom: 4px;
                    font-style: italic;
                }

                .span2 {
                    display: block;
                    @include x03;
                    color: #252525;
                    padding-bottom: 14px;
                }

                .p2 {
                    line-height: 1.6;
                    @include x03;
                    padding-bottom: 45px;
                }
            }
        }
    }
    @media #{$size_1000} {
        &::before {
            background-position: 80% 50%;
        }

        &::after {
            display: block;
        }

        .wrap {
            .title_wrap {
                width: 70%;

                .title1 {
                    padding-bottom: 10px;
                }

                .title2 {
                    padding-bottom: 45px;

                    span {
                        &::after,
                        &::before {
                            width: 25px;
                        }

                        &::before {
                            left: -38px;
                        }

                        &::after {
                            right: -38px;
                        }
                    }
                }

                div {
                    .p1 {
                        @include x01;
                        padding-bottom: 10px;
                    }

                    .span1 {
                        @include lora;
                    }

                    .span2 {
                        padding-bottom: 12px;
                    }

                    .p2 {
                        padding-bottom: 35px;
                    }
                }
            }
        }
    }
    @media #{$size_500} {
        &::before {
            background-position: 80% 50%;
        }

        &::after {
            background-color: RGBA(245, 245, 245, 0.75);
        }

        .wrap {
            display: block;

            .title_wrap {
                width: 100%;

                .title2 {
                    padding-bottom: 35px;
                }

                div {
                    .p1 {
                        @include x01;
                    }

                    .span1 {
                        @include lora;
                    }

                    .span2 {
                        padding-bottom: 10px;
                    }

                    .p2 {
                        padding-bottom: 25px;
                    }
                }
            }
        }
    }
}

.fair_calender {
    @include section_padding;
    @include bcg01;

    .inner {
        .h2_title01 {
            padding-bottom: 40px;
        }

        iframe {
            border: none;
        }

        .btn_out {
            display: none;
        }
    }
    @media #{$size_900} {
        .inner {
            iframe {
                display: none;
            }

            .btn_out {
                display: block;
            }
        }
    }
}

.fair_box3 {
    padding-top: 100px;
    padding-bottom: 100px;

    .main {
        height: 300px;
        background-image: url("#{$base_path}fair/bk1.jpg");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        display: flex;
        justify-content: center;
        align-items: center;
        ul {
            padding-left: 10px;
            padding-right: 10px;
            li {
                color: #fff;
                &:nth-child(1) {
                    @include x03;
                    padding-bottom: 12px;
                }
                &:nth-child(2) {
                    @include lora;
                    font-style: italic;
                    padding-bottom: 4px;
                    a {
                        color: #fff;
                    }
                }
                &:nth-child(3) {
                    @include x03;
                    padding-bottom: 15px;
                }
                &:nth-child(4) {
                    @include x03;
                    line-height: 1.6;
                }
            }
        }
    }
    @media #{$size_1000}{
        padding-top: 50px;
        padding-bottom: 50px;
        .main {
            height: 260px;
            background-position: 60% 50%;
            ul {
                li {
                    &:nth-child(1) {
                        padding-bottom: 12px;
                    }
                    &:nth-child(2) {
                        padding-bottom: 4px;
                    }
                    &:nth-child(3) {
                        padding-bottom: 15px;
                    }
                }
            }
        }
    }
    @media #{$size_500}{
        .main {
            height: 240px;
            background-position: 64% 50%;
            ul {
                li {
                    &:nth-child(1) {
                        padding-bottom: 10px;
                    }
                    &:nth-child(2) {
                        padding-bottom: 0px;
                    }
                    &:nth-child(3) {
                        padding-bottom: 10px;
                    }
                }
            }
        }
    }
}

.fair_body{
  .fair_content01{
    padding-bottom: 0px;
    .fair_list_link{
      display: none;
      a{
        display: none;
      }
    }
  }
}

.btn_out{
  .fair1228_link01{
    @media #{$size_1000}{
      display: none;
    }
  }

  .fair1228_link02{
    display: none;
    @media #{$size_1000}{
      display: block;
    }
  }
}
