@charset "UTF-8";


/*
.repode_box1 {
    width: 100%;
    position: relative;
    z-index: 0;
    .main_pic_box{
      img{
        width: 100%;
        height: auto;
      }
    }
    .text_box {
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        width: 500px;
        bottom: 0;
        display: inline-block;
        box-sizing: border-box;
        @media #{$size_1000} {
          position: static;
          width: 100%;
        }
        article {
            color: #000;
            padding: 40px 70px 50px 70px;
            background-color: rgba(255, 255, 255, 0.831);

            p {
                padding-bottom: 10px;
                @include font_yuu;
            }
            h1 {
                @include x01;
            }
        }

    }
    @media #{$size_1000} {
        .text_box {
            article {
                padding: 20px 40px 20px 40px;
            }

        }
    }
    &:before {
        z-index: -2;
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
    }
}

.repode_box2 {
    padding-top: 100px;
    padding-bottom: 100px;
    div {
        @include font_yuu;
        color: #000;
        text-align: left;
        line-height: 2.0;
    }
    @media #{$size_1000} {
        padding-top: 0px;
        padding-bottom: 30px;
    }
}

.repode_box3 {
    .big {
        width: 100%;
        img {
            width: 100%;
            height: auto;
        }
    }
    .small {
        display: flex;
        div {
            width: 50%;
            img {
                width: 100%;
                height: auto;
            }
        }
    }
    @media #{$size_800} {
        .small {
            display: block;
            div {
                width: 100%;
            }
        }
    }
}

.repode_box4 {
    background-color: #fff8f8;
    padding-top: 250px;
    padding-bottom: 160px;
    .wrap {
        article {
            background-color: #fff;
            position: relative;
            &::before {
                content: "Comment";
                display: inline-block;
                position: absolute;
                top: -140px;
                left: 12%;
                @include font_tang;
                color: #eeb4b4;
                @include fontsize(150);
            }
            div {
                margin-bottom: 90px;
                max-width: 680px;
                margin-left: auto;
                margin-right: auto;
                padding-top: 125px;
                padding-bottom: 155px;
                padding-left: 20px;
                padding-right: 20px;
                box-sizing: border-box;
                color: #000;
                h2 {
                    @include x01;
                    padding-bottom: 45px;
                    @media #{$size_1000} {
                      padding-bottom: 30px;
                    }
                }
                p {
                    @include font_HiraginoProN;
                    text-align: left;
                    line-height: 2.0;
                    @media #{$size_1000} {
                      line-height: 1.6;
                    }
                }
            }
        }
    }
    @media #{$size_1000} {
        padding-top: 100px;
        padding-bottom: 50px;
        .wrap {
            article {
                &::before {
                    top: -95px;
                    left: 6%;
                    @include fontsize(100);
                }
                div {
                    margin-bottom: 50px;
                    padding-top: 50px;
                    padding-bottom: 50px;
                    h2 {
                        @include x01;
                        padding-bottom: 35px;
                    }
                }
            }
        }
    }
    @media #{$size_500} {
        padding-top: 100px;
        padding-bottom: 60px;
        .wrap {
            padding-left: 20px;
            padding-right: 20px;
            article {
                &::before {
                    top: -65px;
                    left: 4%;
                    @include fontsize(70);
                }
                div {
                    margin-bottom: 40px;
                    padding-top: 60px;
                    padding-bottom: 60px;
                    h2 {
                        padding-bottom: 25px;
                    }
                }
            }
        }
    }
}
*/










.report_detail_body{
  .repode_box1 {
      width: 100%;
      position: relative;
      z-index: 0;
      .main_pic_box{
        img{
          width: 100%;
          height: auto;
        }
      }
      .text_box {
          position: absolute;
          left: 0;
          right: 0;
          margin: auto;
          width: 500px;
          bottom: 0;
          display: inline-block;
          box-sizing: border-box;
          @media #{$size_1000} {
            position: static;
            width: 100%;
          }
          article {
              color: #000;
              padding: 40px 20px 50px 20px;
              background-color: rgba(255, 255, 255, 0.831);

              p {
                  padding-bottom: 10px;
                  @include x03;
              }
              h1 {
                  @include x01;
                  color:#76735c;
              }
          }

      }
      @media #{$size_1000} {
          .text_box {
              article {
                  padding: 20px 40px 20px 40px;
              }

          }
      }
      &:before {
          z-index: -2;
          position: absolute;
          top: 0;
          left: 0;
          content: "";
          display: block;
          width: 100%;
          height: 100%;
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
      }
  }

  .repode_box2 {
      padding-top: 100px;
      padding-bottom: 100px;
      div {
          @include x03;
          color: #000;
          text-align: left;
          line-height: 2.0;
      }
      @media #{$size_1000} {
          padding-top: 0px;
          padding-bottom: 30px;
      }
  }

  .repode_box3 {
      .big {
          width: 100%;
          img {
              width: 100%;
              height: auto;
          }
      }
      .small {
          display: flex;
          div {
              width: 50%;
              img {
                  width: 100%;
                  height: auto;
              }
          }
      }
      @media #{$size_800} {
          .small {
              display: block;
              div {
                  width: 100%;
              }
          }
      }
  }

  .repode_box4 {
      background-color: #f7f7f7;
      padding-top: 250px;
      padding-bottom: 160px;
      .wrap {
          article {
              background-color: #fff;
              position: relative;
              &::before {
                  content: "Comment";
                  display: inline-block;
                  position: absolute;
                  top: -140px;
                  left: 12%;
                  font-family: 'Tangerine', cursive;
                  color: #cdcab1;
                  @include fontsize(150);
              }
              .rp04_box {
                  margin-bottom: 90px;
                  max-width: 680px;
                  margin-left: auto;
                  margin-right: auto;
                  padding-top: 125px;
                  padding-bottom: 155px;
                  padding-left: 20px;
                  padding-right: 20px;
                  box-sizing: border-box;
                  color: #000;
                  h2 {
                      @include x01;
                      padding-bottom: 45px;
                      @media #{$size_1000} {
                        padding-bottom: 30px;
                      }
                  }
                  .text_out {
                      @include font_HiraginoProN;
                      text-align: left;
                      line-height: 2.0;
                      h1,h2,h3,h4{
                        @include fontsize(16);
                        font-weight: bold;
                        padding-bottom: 8px;
                        @include x03;
                        color:#555;
                      }
                      p{
                        padding-bottom: 35px;
                        @include x03;
                      }
                      @media #{$size_1000} {
                        line-height: 1.6;
                      }
                  }
              }
          }
      }
      @media #{$size_1000} {
          padding-top: 100px;
          padding-bottom: 50px;
          .wrap {
              article {
                  &::before {
                      top: -95px;
                      left: 6%;
                      @include fontsize(100);
                  }
                  .rp04_box {
                      margin-bottom: 50px;
                      padding-top: 50px;
                      padding-bottom: 50px;
                      h2 {
                          @include x01;
                          padding-bottom: 35px;
                      }
                  }
              }
          }
      }
      @media #{$size_500} {
          padding-top: 100px;
          padding-bottom: 60px;
          .wrap {
              padding-left: 20px;
              padding-right: 20px;
              article {
                  &::before {
                      top: -65px;
                      left: 4%;
                      @include fontsize(70);
                  }
                  .rp04_box {
                      margin-bottom: 40px;
                      padding-top: 60px;
                      padding-bottom: 60px;
                      h2 {
                          padding-bottom: 25px;
                      }
                  }
              }
          }
      }
  }
}
