@charset "UTF-8";
// ページ見出し
.for-guest_main_img {
    background-image: url("#{$base_path}for-guest/for-guest_title.jpg");
    height: 250px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @media #{$size_1000} {
        background-position: center bottom;
        height: 180px;
    }
    @media #{$size_500} {
        background-position: center bottom;
        height: 150px;
    }
}
// for-guest_links_inPage
.for-guest_links_inPage {
    padding: 100px 0;
    @media #{$size_1000} {
        padding: 60px 0;
    }
    @media #{$size_600} {
        padding: 50px 0;
    }
    .part_links_inPage{
      @media #{$size_1000} {
          padding-bottom: 40px;
      }
    }

    p {
        @include fontsize(16);
        @include x03;
        line-height: 3;
        letter-spacing: 1px;
        @media #{$size_1000} {
          line-height: 2;
        }
        @media #{$size_600} {
            @include fontsize(14);
            line-height: 2;
        }
        @media #{$size_500} {
            text-align: left;
            padding: 0 30px;
            @include fontsize(14);

            br {
                display: none;
            }
        }
    }
}
// 各セクションのパディング
.for-guest_sectionPadding {
    padding: 120px 0;
    @media #{$size_1000} {
        padding: 60px 0;
    }
}
//アクセス
.for-guest_access {
    background-color: #fff8f8;

    div.routeByCar {
        padding-bottom: 40px;

        dl {
            dd,
            dt {
                @include fontsize(16);
                @include x03;
                line-height: 2;
                letter-spacing: 2px;
                @media #{$size_800} {
                  line-height: 1.8;
                  letter-spacing: 0;
                }
                @media #{$size_500} {
                    @include fontsize(14);
                    letter-spacing: 0;
                }
            }

            &:first-child {
                padding-bottom: 40px;
                @media #{$size_800} {
                    padding-bottom: 30px;
                }
                @media #{$size_500} {
                    padding-bottom: 20px;
                }
            }
        }
    }
}

div.for-guest_mapArea {
    width: 63%;
    margin: 0 auto;
    padding-bottom: 90px;
    @media #{$size_1000} {
        width: 80%;
        padding-bottom: 80px;
        padding-bottom: 60px;
    }
    @media #{$size_600} {
        width: auto;
        padding: 0 20px 60px;
    }

    div.pic-map {
        width: 100%;
        display: flex;
        justify-content: center;
        padding-bottom: 40px;
        @media #{$size_1000} {
            display: block;
            padding-bottom: 0;
        }

        div {
            width: 50%;
            @media #{$size_1000} {
                width: 100%;
                padding-bottom: 30px;
            }

            img {
                display: block;
                width: 100%;
            }
        }
    }

    div.for-guest_mapBtn {
        a {
            @include fontsize(16);
            @include x03;
        }
    }
}

.for-guest_shuttleBus {
    dl {
        width: 42%;
        display: block;
        margin: 0 auto;
        @media #{$size_1000} {
            width: 80%;
        }
        @media #{$size_600} {
            width: auto;
            padding: 0 20px;
        }

        dt {
            @include fontsize(28);
            @include font_HiraginoProN;
            line-height: 1.7;
            letter-spacing: 2px;
            padding-bottom: 45px;
            @media #{$size_1000} {
                @include fontsize(20);
                letter-spacing: 0;
                padding-bottom: 20px;
            }
            @media #{$size_500} {
                @include fontsize(18);
            }
        }

        dd.for-guest_busMap {
            padding-bottom: 40px;
            @media #{$size_1000} {
              padding-bottom: 20px;
            }
            div {
                width: 100%;

                img {
                    display: block;
                    width: 100%;
                }
            }
        }

        dd.for-guest_busInfo {
            @include fontsize(14);
            @include x03;
            color: #444;
            line-height: 2.25;
            text-align: left;
            letter-spacing: 1px;
            padding-bottom: 10px;
            @media #{$size_500} {
                @include fontsize(14);
                letter-spacing: 1px;
                line-height: 1.8;
            }
        }

        dd.for-guest_busTime {
            @include fontsize(14);
            @include x03;
            color: #444;
            line-height: 2.25;
            text-align: left;
            text-indent: 2rem;
            position: relative;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding-left: 25px;
            @media #{$size_500} {
                @include fontsize(14);
                letter-spacing: 1px;
                line-height: 1.8;
            }

            &::before {
                display: block;
                content: '';
                width: 12px;
                height: 12px;
                background-color: #444;
                border-radius: 50%;
                position: absolute;
                left: 10px;
                @media #{$size_500} {
                    width: 10px;
                    height: 10px;
                }
            }
        }
    }
}
//サービス
.for-guest_service {
    ul.for-guest_serviceList {
        max-width: 1200px;
        margin: 0 auto;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        @media #{$size_1200} {
            width: 80%;
        }
        @media #{$size_800} {
          width: 100%;
        }
        @media #{$size_500} {
            width: auto;
            padding: 0 30px;
        }

        li {
            width: 31.33%;
            margin-right: 3%;
            padding-bottom: 60px;
            @media #{$size_1000} {
              padding-bottom: 30px;
            }

            &:nth-child(3n) {
                margin-right: 0;
            }
            @media #{$size_1000} {
                width: 48%;
                margin-right: 0;

                &:nth-child(odd) {
                    margin-right: 4%;
                }
            }
            @media #{$size_800} {
                width: 100%;
                margin-right: 0;

                &:nth-child(odd) {
                    margin-right: 0;
                }
            }
            @media #{$size_500} {
                padding-bottom: 40px;
            }
        }

    }
}

.for-guest_service ul.for-guest_serviceList li {
    dl {
        @media #{$size_1000} {
          padding-left: 10px;
          padding-right: 10px;
          box-sizing: border-box;
        }
        dt {
            @include fontsize(19);
            @include x03;
            line-height: 2.6;
            text-align: left;
            padding-bottom: 15px;
            @media #{$size_1000} {
              line-height: 1.8;
              padding-bottom: 10px;
            }
            @media #{$size_800} {
                text-align: center;
            }
            @media #{$size_500} {
                padding-bottom: 10px;
            }

            div {
                width: 100%;
                padding-bottom: 15px;

                img {
                    display: block;
                    width: 100%;
                }
            }
        }

        dd {
            @include fontsize(16);
            @include x03;
            line-height: 1.75;
            text-align: left;
            @media #{$size_500} {
                @include fontsize(14);
                line-height: 1.5;
            }
        }
    }
}

.new_font{
  dt,dd{
  font-family: 'メイリオ',Meiryo,'ヒラギノ角ゴ Pro W3','Hiragino Kaku Gothic Pro',sans-serif !important;
  }
}
// faq
.for-guest_faq {
    background-color: #fff8f8;

    ul.for-guest_faqList {
        max-width: 1200px;
        margin: 0 auto;
        @media #{$size_1200} {
            width: 80%;
        }
        @media #{$size_500} {
            width: auto;
            padding: 0 30px;
        }

        li {
            padding: 40px 0 40px 70px;
            text-align: left;
            border-top: 1px solid #f8e1e1;
            @media #{$size_800} {
                padding: 30px 0 30px 60px;
            }
            @media #{$size_500} {
                padding: 30px 5px 30px 30px;
            }

            &:last-child {
                border-bottom: 1px solid #f8e1e1;
            }
        }
    }
}

.for-guest_faq ul.for-guest_faqList li {
    dl {
        dt {
            @include fontsize(17);
            @include x03;
            line-height: 1.76;
            position: relative;
            display: flex;
            align-items: center;
            padding-bottom: 20px;
            letter-spacing: 2px;
            font-weight: bold;
            @media #{$size_1000} {
                @include fontsize(15);
                line-height: 1.4;
            }

            &::before {
                display: block;
                content: 'Q';
                @include fontsize(28);
                @include lora;
                color: #eab0b7;
                position: absolute;
                left: -30px;
            }
        }

        dd {
            @include fontsize(16);
            line-height: 1.5;
            letter-spacing: 2px;
            @include x03;
            @media #{$size_1000} {
                @include fontsize(14);
                letter-spacing: 1px;
            }
        }
    }
}
