@charset "utf-8";

$base_path: '/assets/images/';

@mixin font_yuu {
	font-family:Ryumin Regular KL;
	//font-family: 游明朝,"Yu Mincho",YuMincho,"Hiragino Mincho ProN",HGS明朝E,メイリオ,Meiryo,serif;
	//font-family: "游ゴシック", "Yu Gothic", YuGothic, "Hiragino Kaku Gothic ProN", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ ゴシック", sans-serif;
}
@mixin font_tang {
	font-family:Ryumin Regular KL;
	//font-family: 'Tangerine', cursive;
}


@mixin font_HiraginoProN {
	font-family:Ryumin Regular KL;
	//font-family: 游明朝,"Yu Mincho",YuMincho,"Hiragino Mincho ProN",HGS明朝E,メイリオ,Meiryo,serif;
}

@mixin font_Meiryo {
	font-family:Ryumin Regular KL;
	//font-family: 游明朝,"Yu Mincho",YuMincho,"Hiragino Mincho ProN",HGS明朝E,メイリオ,Meiryo,serif;
	//font-family: Meiryo,'メイリオ','ヒラギノ角ゴ Pro W3','Hiragino Kaku Gothic Pro',sans-serif;
}

// ↓　使用を NotoSansMedium に変更する
@mixin font_NotoSansMedium {
	font-family:Ryumin Regular KL;
	//font-family: 游明朝,"Yu Mincho",YuMincho,"Hiragino Mincho ProN",HGS明朝E,メイリオ,Meiryo,serif;
	//font-family: "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, "ＭＳ ゴシック", sans-serif;
}
