@charset "UTF-8";

// ページ見出し
.report_main_img {
    background-image: url("#{$base_path}report/report_title.jpg");
    height: 250px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @media #{$size_1000} {
      background-position: right bottom;
      height: 180px;
    }

    @media #{$size_500} {
      background-position: right bottom;
      height: 150px;
    }

}

// introText
.report_introText {
  padding: 100px 0;
  @include fontsize(16);
  line-height: 2.75;
  letter-spacing: 3px;
  @include x03;

  @media #{$size_1000} {
    padding: 80px 0;
    line-height: 2.0;
    letter-spacing: 1px;
  }
  @media #{$size_700} {
    padding: 70px 0;
    line-height: 2.0;
    @include fontsize(16);
  }
  @media #{$size_500} {
    padding: 50px 0;
    line-height: 1.8;
    @include fontsize(14);
  }
}

// report_contents
.report_contents {
  max-width: 1200px;
  margin: 0 auto;
  width: 62.5%;

  @media #{$size_1000} {
    padding: 0 0px;
    width: 100%;
    max-width: auto;

  }
  @media #{$size_500} {
    width: auto;
  }
}


.report_contents ul.report_list {
  li {
    display: flex;
    justify-content: center;
    padding-bottom: 110px;

    div.report_imgBox-L {
      width: 58%;
      margin-right: 3%;

      img {
        display: block;
        width: 100%;
      }
    }

    div.report_listIntro {
      width: 38%;

      dl {
        display: block;
        width: 100%;

        dt.listIntro_img {
          width: 100%;
          display: block;
          position: relative;

          div.report_imgBox-S {
            width: 100%;
            display: block;

            img {
              display: block;
              width: 100%;
            }
          }

          div.listIntro_title {
            position: absolute;
            width: 100%;
            height: 20%;
            background-color: rgba(255,255,255, 0.8);
            bottom: 0;
            text-indent: 2rem;
            @include lora;
            @include fontsize(16);
            letter-spacing: 2px;
            display: flex;
            align-items: center;
          }
        }

        dd.listIntro_excerpt {
          @include x03;
          @include fontsize(14);
          line-height: 1.75;
          padding: 20px 0;
          text-align: left;
          font-weight: normal;
        }
      }

    }
  }
}

.report_contents ul.report_list li {
  @media #{$size_1200} {
    display: block;
    text-align: center;

    div.report_imgBox-L {
      width: 100%;
      margin-right: 0;
      margin-bottom: 20px;
    }

    div.report_listIntro {
      width: 100%;
      margin-right: 0;

      dl {
        dt.listIntro_img {
          div.listIntro_title {
            text-indent: 0;
            @include fontsize(18);
            justify-content: center;
            height: 15%;
          }
        }

        dd.listIntro_excerpt {
          @include fontsize(16);
          text-align: center;
          padding: 40px 0;
        }
      }
    }
  }
}

.report_contents ul.report_list li {
  @media #{$size_1000} {
    padding-bottom: 80px;
    div.report_imgBox-L{
      margin-bottom: 0;
    }
    div.report_listIntro {

      dl {
        dt.listIntro_img {
          div.listIntro_title {
            @include fontsize(16);
            height: 15%;
          }
        }

        dd.listIntro_excerpt {
          @include fontsize(14);
          text-align: left;
          padding: 30px 0;
          @media #{$size_1000} {
            padding-left: 30px;
            padding-right: 30px;
            box-sizing: border-box;
          }
        }
      }
    }
  }
}

.report_contents ul.report_list li {
  @media #{$size_500} {
    padding-bottom: 80px;

    &:last-child {
      padding-bottom: 100px;
    }
  }
}

.report_contents ul.report_list li:nth-child(even) {
  flex-direction: row-reverse;

  div.report_imgBox-L {
    margin-right: 0;
  }

  div.report_listIntro {
    margin-right: 3%;
  }
}
