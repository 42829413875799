@charset "utf-8";
// ページ見出し
.access_main_img {
    background-image: url("#{$base_path}access/access_title.jpg");
    height: 250px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    @media #{$size_1000} {
        background-position: center bottom;
        height: 180px;
    }
    @media #{$size_500} {
        background-position: center bottom;
        height: 150px;
    }
}
//access_googleMap
.access_googleMap {
    padding: 120px 30px;
    @media #{$size_1200} {
        padding: 100px 0;
    }
    @media #{$size_1000} {
      padding: 60px 20px;
    }
    @media #{$size_600} {
        padding: 80px 20px;
    }

    div.access_iframe {
        max-width: 1200px;
        margin: 0 auto;
        padding-bottom: 30px;
        @media #{$size_1200} {
            width: 80%;
        }
        @media #{$size_1000} {
          padding-bottom: 20px;
        }
        @media #{$size_600} {
            width: auto;
        }

        div.iframe_wrapper {
            position: relative;
            width: 100%;
            padding-top: 50%;
            @media #{$size_800} {
                padding-top: 65%;
            }

            iframe {
                position: absolute;
                top: 0;
                right: 0;
                width: 100% !important;
                height: 100% !important;
            }
        }
    }
}

.access_googleMap div.access_mapArea {
    padding-bottom: 50px;
    @media #{$size_1000} {
      padding-bottom: 30px;
    }

    h3.access_address {
        @include fontsize(16);
        @include x03;
        line-height: 2.5;
        padding-bottom: 25px;
        letter-spacing: 2px;
        @media #{$size_800} {
            letter-spacing: 1px;
        }
        @media #{$size_600} {
            @include fontsize(14);
        }
    }

    div.access_mapBtn {
        a {
            @include fontsize(16);
            @include x03;
        }
    }
}

.access_googleMap div.access_routeByCar_text {
    dl {
        @include fontsize(16);
        @include x03;
        line-height: 1.75;
        @media #{$size_600} {
            @include fontsize(14);
        }

        &:first-child {
            padding-bottom: 25px;
            @media #{$size_1000} {
              padding-bottom: 15px;
            }
        }

        dd,
        dt {
            letter-spacing: 1px;
        }

        dt {
            padding-bottom: 10px;
        }
    }
}
//access_illustMap
.access_illustMap {
    background-color: #fff8f8;
    padding: 120px 0;
    @media #{$size_1200} {
        padding: 100px 0;
    }
    @media #{$size_1000} {
      padding: 60px 0;
    }
    @media #{$size_700} {
        padding: 60px 20px;
    }

    div.illustMap_title {
        padding-bottom: 55px;
        @media #{$size_1000} {
            padding-bottom: 20px;
        }

        h3 {
            @include fontsize(36);
            @include lora;
            color: #eab0b7;
            line-height: 1.4;
            letter-spacing: 0.1em;
            padding-bottom: 13px;
            @media #{$size_1000} {
                @include fontsize(28);
                padding-bottom: 5px;
            }
        }

        p {
            @include fontsize(18);
            @include font_HiraginoProN;
            line-height: 2.7;
            letter-spacing: 0.2em;
            position: relative;
            display: inline-block;
            align-items: center;
            @media #{$size_1000} {
                letter-spacing: 1px;
            }
            @media #{$size_1000} {
                @include fontsize(16);
            }

            &::after,
            &::before {
                content: "";
                position: absolute;
                display: block;
                width: 44px;
                height: 1px;
                background-color: #252525;
                top: 50%;
                @media #{$size_1000} {
                    width: 25px;
                }
            }

            &::before {
                left: -65px;
                @media #{$size_1000} {
                    left: -35px;
                }
            }

            &::after {
                right: -65px;
                @media #{$size_1000} {
                    right: -35px;
                }
            }
        }
    }
}

.access_illustMap div.illustMap_mapArea {
    width: 60%;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    @media #{$size_1200} {
        width: 80%;
    }
    @media #{$size_1000} {
        display: block;
    }
    @media #{$size_700} {
        width: 100%;
    }

    div.topMap {
        width: 47%;
        margin-right: 6%;
        @media #{$size_1000} {
            width: 100%;
            margin-right: 0;
            padding-bottom: 40px;
        }

        img {
            display: block;
            width: 100%;
        }
    }

    div.detailMaps {
        width: 47%;
        @media #{$size_1000} {
            width: 100%;
            max-width: 500px;
            margin: 0 auto;
        }

        dl {
            width: 100%;

            dt {
                @include fontsize(16);
                @include x03;
                line-height: 3;
                text-align: left;
                letter-spacing: 1px;
                @media #{$size_1000} {
                    text-align: center;
                }
                @media #{$size_700} {
                    text-align: left;
                }
            }

            dd {
                @include fontsize(12);
                @include x03;
                line-height: 1.5;
                color: #1f1813;
                text-align: left;
                @media #{$size_1000} {
                    text-align: center;
                }
                @media #{$size_700} {
                    text-align: left;
                }

                div {
                    width: 100%;

                    img {
                        display: block;
                        width: 100%;
                    }
                }
            }
        }

        dl.detailMaps_parking {
            padding-bottom: 40px;
        }
        .detailMaps_bus {
            dd {
                max-width: 265px;
                &:nth-child(3) {
                    padding-bottom: 14px;
                }
            }
            @media #{$size_1000} {
                dd {
                    margin: auto;
                    text-align: left;
                }
                dt {
                    max-width: 265px;
                    margin: auto;
                }
            }
        }
    }
}

.access_busTime {
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 12px;
    @media #{$size_1000} {
        // justify-content: center;
    }
    @media #{$size_700} {
        justify-content: flex-start;
    }

    &::before {
        display: block;
        content: '';
        width: 8px;
        height: 8px;
        background-color: #252525;
        border-radius: 50%;
        position: absolute;
        top: 5px;
        left: 0;
        @media #{$size_1000} {
            left: 0;
        }
        @media #{$size_700} {
            left: 0;
        }
    }
}
