@charset "UTF-8";
@import url('https://fonts.googleapis.com/css?family=Lora');
@import url('https://fonts.googleapis.com/css?family=Tangerine');
//セッティング
@import "base/setting";
@import "base/setting2";

//パーツ
@import "base/parts2";
//フェア
@import "layout/fair/index";

@import "layout/ceremony/index";

@import "layout/gallery/index";

//レポート
@import "layout/report/index";

@import "layout/report_detail/index";

@import "layout/plan/index";

@import "layout/plan_detail/index";

@import "layout/party/index";

@import "layout/for-guest/index";

@import "layout/access/index";

@import "layout/privacy-policy/index";
