@charset "UTF-8";

.party_box1 {
    &::before {
        background-image: url("#{$base_path}party/main.jpg");
    }
    display: flex;
    align-items: center;
    justify-content: center;

    .main_title {
        .title1 {
            @include fontsize(60);
            padding-bottom: 22px;
        }
    }
    @media #{$size_1000} {
        .main_title {
            .title1 {
                @include fontsize(48);
                padding-bottom: 20px;
            }
        }
    }
    @media #{$size_500} {
        .main_title {
            .title1 {
                @include fontsize(36);
                padding-bottom: 15px;
            }
        }
    }
}

.party_box2 {
    @media #{$size_1000} {
        padding-bottom: 60px;
    }
}

.party_box3 {
    .main {
        .wrap {
            @media #{$size_1000} {
                padding-bottom: 0px;
            }
            article {
                div {
                    padding: 110px 110px 200px;
                }
            }
        }
    }
    @media #{$size_1000} {
        .main {
            .wrap {
                padding-bottom: 0;
                article {
                    div {
                        padding: 60px 30px 0px 30px;
                    }
                }
            }
        }
    }
    @media #{$size_500} {
        .main {
            .wrap {
                article {
                    div {
                        padding: 40px 20px ;
                    }
                }
            }
        }
    }
}

.party_box4 {
    margin-bottom: 250px;
    padding-bottom: 0px !important;
    background-image: url("#{$base_path}party/bk2.jpg");
    background-position: 10% center;

    article {
        margin-bottom: 145px;
    }
    @media #{$size_1000} {
        margin-bottom: 60px;
        article {
            margin-bottom: 60px;
        }
    }
    @media #{$size_500} {
        article {
            margin-bottom: -20px;
        }
    }
    @media #{$size_400} {
        article {
            margin-bottom: -95px;
        }
    }
}

.party_box6 {
    height: 1429px;
    background-image: url("#{$base_path}party/bk4.jpg");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    .wrap {
        padding-top: 120px;
        article {
            color: #fff;
            h2 {
                @include x01;
                padding-bottom: 50px;
            }
            p {
                @include x03;
                line-height: 2.5;
                padding-bottom: 70px;
            }
        }
    }

    @media #{$size_1000} {
        height: 1000px;
        .wrap {
            padding-top: 60px;
            article {
                h2 {
                    padding-bottom: 30px;
                }
                p {
                    padding-bottom: 50px;
                }
            }
        }
    }
    @media #{$size_500} {
        .wrap {
            article {
                h2 {
                    padding-bottom: 20px;
                }
                p {
                    padding-bottom: 30px;
                    line-height: 2.0;
                }
            }
        }
    }
}

.party_box5 {
  .main {
    .wrap {
      @media #{$size_1000} {
        padding-bottom: 0px;
      }
      @media #{$size_500} {
        padding-bottom: 0px;
      }
    }
  }
}
