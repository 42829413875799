@charset "UTF-8";

// ページ見出し
.gallery_main_img {
    background-image: url("#{$base_path}gallery/gallery_title-01.jpg");
    height: 200px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @media #{$size_1000} {
      background-position: right bottom;
      height: 180px;
    }

    @media #{$size_500} {
      background-position: right bottom;
      height: 150px;
    }

}

// ページナビ
.gallery_page_nav {
  padding: 80px 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  div.categoryBtn {
    margin: 10px 15px 15px 0;

    a {
      height: 40px;
      color: #e08a94;
      border-radius: 20px;
      background-color: #fff;
      text-align: center;
      display: inline;
      border: 1px solid #f9d4d8;
      padding: 8px 25px;
      vertical-align: middle;
      @include x03;
      &:hover,
      &:active,
      &.active {
      color: #fff;
      background-color: #e08a94;
      border: 1px solid #e08a94;
      }

     }

    &:last-child {
      margin-right: 0;
    }

  }
}

.gallery_page_nav {
  @media #{$size_1000} {
    padding: 50px 0;

    div.categoryBtn {

      a {
        @include fontsize(14);
      }
    }
  }
}

.gallery_page_nav {
  @media #{$size_500} {
    padding: 30px 0;

    div.categoryBtn {
      a {
        padding: 10px 20px;
        height: 30px;
        @include fontsize(13);
      }
    }
  }
}

// メインコンテンツ
.gallery_contents {
  div.contents_top {
    width: 100%;

    img {
      width: 100%;
      display: block;
    }
  }

  p {
    width: 50%;
    padding: 105px 0;
    margin: 0 auto;
    text-align: left;
    line-height: 2.25;
    @include fontsize(16);
    @include x03;
    @media #{$size_1000} {
      width: 72%;
      padding: 80px 0;
      line-height: 2.0;
    }

    @media #{$size_500} {
      width: 85%;
      padding: 50px 0;
      line-height: 2.0;
      @include fontsize(14);
    }
  }
}

.gallery_contents {
  div.contents_box {
    display:flex;
    justify-content: center;
    @media #{$size_1000} {
      display: block;
      //max-width: 500px;
      margin: 0 auto;
      padding: 0;

      img {
        padding-bottom: 20px;
        @media #{$size_1000} {
          padding-bottom: 0px;
        }
      }

      &:last-child {
        div:last-child img{
          padding-bottom: 50px;
        }
      }
    }

    div.imgBox_sizeL {
      width: 66%;
      @media #{$size_1000} {
        width: 100%;
      }

      img {
        display: block;
        width: 100%;
        height: 100%;
      }
    }

    div.imgBox_sizeS {
      width: 34%;
      @media #{$size_1000} {
        width: 100%;
      }

      img {
        width: 100%;
        display: block;
      }
    }

    div.imgBox_sizeM,
     {
      width: 50%;
      @media #{$size_1000} {
        width: 100%;
      }

      img {
        width: 100%;
        height: 100%;
        display: block;
      }
    }

  }
}

.gallery_contents {
  div.imgBox_sizeMAX {
    display: block;

    img {
      display: block;
      width: 100%;
    }
  }
}
