@charset "UTF-8";

@charset "UTF-8";

// ページ見出し
.privacy-policy_main_img {
    background-image: url("#{$base_path}privacy-policy/privacy-policy_title.jpg");
    height: 250px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    @media #{$size_1000} {
      background-position: 41% bottom;
      height: 180px;
    }

    @media #{$size_500} {
      background-position: 44% bottom;
      height: 150px;
    }

}

// privacy-policy_contents
.privacy-policy_contents {
  padding: 135px 0 150px;
  background-color: #fff8f8;
  @media #{$size_1000} {
    padding: 0;
  }

  div.contentsFrame {
    margin: 0 auto;
    padding: 130px 0;
    background-color: #fff;
    max-width: 1400px;
    width: 73%;
    @media #{$size_1300} {
      padding: 110px 0;
    }
    @media #{$size_1000} {
      padding: 40px 0 ;
      width: 100%;
    }
    @media #{$size_500} {
      padding: 50px 0;
      width: auto;
    }

    div.contentsWrapper {
      width: 71.4%;
      margin: 0 auto;
      @media #{$size_1300} {
        width: 80%;
      }
      @media #{$size_1000} {
        width: 100%;
        padding-left: 30px;
        padding-right: 30px;
        box-sizing: border-box;
      }

    }
  }
}

div.contentsWrapper {
  p.preface {
    @include x03;
    @include fontsize(18);
    line-height: 2.2;
    letter-spacing: 0.5px;
    padding-bottom: 70px;
    @media #{$size_1200} {
      @include fontsize(16);
    }
    @media #{$size_1000} {
      text-align: left;
      line-height: 1.8;
      padding: 0 0px 20px;
      @include fontsize(14);
      letter-spacing: 1px;
    }
    @media #{$size_500} {
      padding: 0 0px 50px;
      letter-spacing: 0.5px;
    }
  }

  ul.clause {
    text-align: left;
    @include x03;
    line-height: 1.8;

    li {
      padding-bottom: 40px;
      @media #{$size_1000} {
        padding-bottom: 20px;
      }

      h3 {
        @include fontsize(18);
        font-weight: 600;
        padding-bottom: 20px;
        @media #{$size_1200} {
          @include fontsize(16);
          padding-bottom: 10px;
        }
        @media #{$size_1000} {
          @include fontsize(14);
          padding-bottom: 5px;
        }

      }

      p, h4 {
        @include fontsize(16);
        @media #{$size_1200} {
          @include fontsize(14);
        }
      }
    }
  }
}

div.contentsWrapper {
  dl.privacy-policy_contact {
    text-align: left;
    @include font_yuu;
    @include fontsize(16);
    line-height: 1.8;
    @media #{$size_1000} {
      @include fontsize(14);
    }
    @media #{$size_500} {
      padding: 0 20px;
    }

    dt {
      position: relative;
      display: flex;
      align-items: center;

      &::before {
        display: block;
        content:'';
        width: 13px;
        height: 13px;
        background-color: #252525;
        left: 0;
        margin-right: 5px;
        @media #{$size_1000} {
          width: 10px;
          height: 10px;
        }

      }
    }
  }
}
