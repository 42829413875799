@charset "utf-8";

.pattern_bannaer1 {
    display: flex;

    a {
        width: 50%;
        display: flex;
        align-items: flex-end;
        background-size: cover;
        background-repeat: no-repeat;
        height: 426px;

        div {
            padding-left: 40px;
            padding-bottom: 34px;
            text-align: left;

            p {
                @include lora;
                padding-bottom: 16px;
            }

            span {
                display: block;
                @include font_yuu;
            }
        }

        &:nth-child(1) {
            color: #000;
            background-position: 80% 50%;
            background-image: url("#{$base_path}common/footer_banner1.jpg");
        }

        &:nth-child(2) {
            color: #fff;
            background-position: 60% 50%;
            background-image: url("#{$base_path}common/footer_banner2.jpg");
        }
    }
    @media #{$size_1000} {
        display: block;

        a {
            height: 300px;
            width: 100%;

            div {
                padding-left: 30px;
                padding-bottom: 24px;

                p {
                    padding-bottom: 12px;
                }
            }
        }
    }
    @media #{$size_500} {
        display: block;

        a {
            div {
                p {
                    padding-bottom: 6px;
                }
            }
        }
    }
}

.pattern_bannaer2 {
    background-color: #fff7f7;
    padding-top: 80px;
    padding-bottom: 80px;

    .wrap {
        max-width: 1220px;
        margin-left: auto;
        margin-right: auto;
        box-sizing: border-box;
        display: flex;

        a {
            width: 50%;
            padding-left: 30px;
            padding-right: 30px;
            display: block;
            height: 245px;
            overflow: hidden;
            box-sizing: border-box;

            .fp_inner{
              position: relative;
              z-index: 0;
              width: 100%;
              height: 245px;
              overflow: hidden;
              @media #{$size_500} {
                  height: 180px;
              }
              &:before{
                width: 100%;
                height: 100%;
                background-repeat: no-repeat;
                transform: scale(1);
                background-size: cover;
                transition: 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
                content:"";
                position: absolute;
                left: 0;
                top: 0;
                z-index: -2;
                display: block;


              }
              &:after{
                width: 100%;
                height: 100%;
                transition: 0.8s cubic-bezier(0.165, 0.84, 0.44, 1);
                content:"";
                position: absolute;
                left: 0;
                top: 0;
                z-index: -1;
                display: block;
                background-color: #000;
                opacity: 0;
              }
            }
            .fp_box01{
                padding-left: 35px;
                padding-bottom: 26px;
                text-align: left;
                position: absolute;
                bottom: 0;
                left: 0;
                @media #{$size_500} {
                  padding-left: 15px;
                  padding-bottom: 15px;
                }
                p {
                    @include lora;
                    padding-bottom: 15px;
                }

                span {
                    display: block;
                    @include font_yuu;
                }
            }

            &:nth-child(1) {
              .fp_inner{
                &:before{
                display: block;
                color: #000;
                background-position: right top;
                background-image: url("#{$base_path}common/footer_banner3.jpg");

                }
                @media #{$size_1000} {
                  margin-bottom: 40px;
                }
              }
            }

            &:nth-child(2) {
              .fp_inner{
                color: #fff;
                &:before{
                display: block;
                margin-right: 0;

                background-position: right top;
                background-image: url("#{$base_path}common/footer_banner4.jpg");
                }
              }
            }
            &:hover{
              .fp_inner{
                &:before{
                transform: scale(1.1);
                transition: 1.3s cubic-bezier(0.165, 0.84, 0.44, 1);
                }
                &:after{
                  opacity: 0.3;
                transition: 1.3s cubic-bezier(0.165, 0.84, 0.44, 1);
                }
              }

            }
        }//a
    }
    @media #{$size_1000} {
        padding-top: 60px;
        padding-bottom: 60px;

        .wrap {
            display: block;
            text-align: center;
            a {
                width: 100%;
                max-width: 500px;
                margin: 0 auto;

                .fp_box01{
                    padding-left: 20px;
                    padding-bottom: 20px;

                    p {
                        padding-bottom: 6px;
                    }

                    span {
                        letter-spacing: -1px;
                        @include fontsize(15);
                    }
                }

                &:nth-child(1) {
                    background-position: 70% 50%;
                    margin-bottom: 20px;
                }

                &:nth-child(2) {
                    background-position: 60% 50%;
                }
            }
        }
    }
    @media #{$size_500} {
        .wrap {
            a {
                height: 180px;
            }
        }
    }
}

.pattern_main_img1 {
    position: relative;
    width: 100%;
    height: calc(100vh - 70px);

    &::before {
        z-index: -2;
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        display: block;
        width: 100%;
        height: 100%;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }

    &::after {
        z-index: -1;
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        display: none;
        width: 100%;
        height: 100%;
        background-color: RGBA(245, 245, 245, 0.45);
    }
    @media #{$size_500} {
        height: 90vh;
    }
}

.pattern_title1 {
    color: #fff;

    .title1 {
        @include fontsize(48);
        letter-spacing: 0.1em;
        margin-bottom: 13px;
        @include lora;
        line-height: 1.2;
    }

    .title2 {
        @include fontsize(18);
        letter-spacing: 0.2em;
        position: relative;
        display: inline-block;

        &::after,
        &::before {
            content: "";
            position: absolute;
            display: block;
            width: 44px;
            height: 1px;
            background-color: #fff;
            top: 12px;
        }

        &::before {
            left: -65px;
        }

        &::after {
            right: -65px;
        }
    }
    @media #{$size_1000} {
        .title1 {
            @include fontsize(36);
        }

        .title2 {
            @include fontsize(16);

            &::after,
            &::before {
                width: 25px;
            }

            &::before {
                left: -35px;
            }

            &::after {
                right: -35px;
            }
        }
    }
    @media #{$size_500} {
        .title1 {
            @include fontsize(28);
        }
    }
}

.pattern_scroll_down {
    position: absolute;
    bottom: 40px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    z-index: 1;
    text-align: center;

    a {
        display: inline-block;

        .arrow {
            width: 86px;
            margin: auto;
            padding-bottom: 24px;
            transition: 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);

            img {
                width: 100%;
                height: auto;
            }
        }

        .text {
            color: #fff;
            @include font_yuu;
            font-weight: 500;
            letter-spacing: 0.2em;
            @include fontsize(14);
        }

        &:hover {
            .arrow {
                transition: 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
                transform: translateY(20%);
            }
        }
    }
    @media #{$size_1000} {
        bottom: 30px;

        a {
            .arrow {
                width: 65px;
                padding-bottom: 24px;
            }
        }
    }
}

.pattern_max1620padding30 {
    max-width: 1680px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 30px;
    padding-right: 30px;
    box-sizing: border-box;
}

.pattern_max1400padding30 {
    max-width: 1460px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 30px;
    padding-right: 30px;
    box-sizing: border-box;
}

.pattern_max1240padding30 {
    max-width: 1300px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 30px;
    padding-right: 30px;
    box-sizing: border-box;
}

.pattern_max1200padding30 {
    max-width: 1260px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 30px;
    padding-right: 30px;
    box-sizing: border-box;
}

.pattern_max800padding30 {
    max-width: 860px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 30px;
    padding-right: 30px;
    box-sizing: border-box;
}

.pattern_max500padding30 {
    max-width: 560px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 30px;
    padding-right: 30px;
    box-sizing: border-box;
}

.pattern_size48_36_28 {
    @include fontsize(48);
    @media #{$size_1000} {
        @include fontsize(36);
    }
    @media #{$size_500} {
        @include fontsize(28);
    }
}

.pattern_size36_30_26 {
    @include fontsize(36);
    @media #{$size_1000} {
        @include fontsize(30);
    }
    @media #{$size_500} {
        @include fontsize(26);
    }
}

.pattern_size28_25_22 {
    @include fontsize(28);
    @media #{$size_1000} {
        @include fontsize(25);
    }
    @media #{$size_500} {
        @include fontsize(22);
    }
}

.pattern_size20_18_17 {
    @include fontsize(20);
    @media #{$size_1000} {
        @include fontsize(18);
    }
    @media #{$size_500} {
        @include fontsize(17);
    }
}

.pattern_size18_17_16 {
    @include fontsize(18);
    @media #{$size_1000} {
        @include fontsize(17);
    }
    @media #{$size_500} {
        @include fontsize(16);
    }
}

.pattern_size16____ {
    @include fontsize(16);
}

.pattern_size16_15__ {
    @include fontsize(16);
    @media #{$size_1000} {
        @include fontsize(15);
    }
}

.pattern_size14____ {
    @include fontsize(14);
}

.pattern_br700 {
    display: none;
    @media #{$size_700} {
        display: block;
    }
}

.pattern_br500 {
    display: none;
    @media #{$size_400} {
        display: block;
    }
}

.pattern_br400 {
    display: none;
    @media #{$size_400} {
        display: block;
    }
}


/*ここから*/


.part_imgLink {
  display: flex;
  justify-content: center;
  background-color: #fff7f7;
  padding: 80px 50px;
  @media #{$size_1000} {
    flex-direction: column;
    align-items: center;
    padding: 90px 30px;
  }
  @media #{$size_500} {
    flex-direction: column;
    align-items: center;
    padding: 60px 30px 90px;
  }


  .part_imgLink_box01 {
    max-width: 580px;
    margin-right: 2%;
    @media #{$size_1000} {
      margin-right: 0;
      margin-bottom: 30px;
    }
  }

  .part_imgLink_box02 {
    max-width: 540px;
  }

  .part_imgLink_box01,
  .part_imgLink_box02 {

    a {
      display: block;
      width: 100%;
      height: 100%;

      img {
        width: 100%;
        display: block;
      }
    }
  }
}

.part_pagination {
  padding: 0 0 100px;
  display: flex;
  justify-content: center;
  @media #{$size_1000} {
    padding: 0 0 80px;
  }

  a, span {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 44px;
    height: 44px;
    background-color: #ffe7e7;
    @include font_Meiryo;
    @include fontsize(16);
    margin-right: 10px;

    @media #{$size_500} {
      width: 40px;
      height: 40px;
    }
  }

  a.link_next {
    margin-right: 0;
  }

  a:hover, span.current_page {
    background-color: #e08a94;
    color: #fff;
  }

}

.part_links_inPage {
  display: flex;
  justify-content: center;
  padding-bottom: 80px;
  @media #{$size_600} {
    padding-bottom: 50px;
  }

  .linkBtn {
    margin-right:15px;
    @media #{$size_600} {
      margin-right:10px;
    }
    @media #{$size_400} {
      margin-right:5px;
    }

    &:last-child {
      margin-right: 0;
    }

    a {
      height: 40px;
      width: 180px;
      color: #fff;
      border-radius: 20px;
      background-color: #dea1a8;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #dea1a8;
      vertical-align: middle;
      @include fontsize(16);
      @include x03;

      @media #{$size_600} {
        height: 35px;
        width: 105px;
        @include fontsize(12);
      }
      @media #{$size_400} {
        width: 98px;
      }

      &:hover {
        color: #dea1a8;
        background-color: #fff;
      }

    }
  }
}

.part_for-guest_sectionTitle {
  @include fontsize(28);
  @include font_HiraginoProN;
  line-height: 1.8;
  letter-spacing: 3px;
  padding-bottom: 50px;
  @media #{$size_1000} {
    padding-bottom: 20px;
  }
  @media #{$size_800} {
    @include fontsize(24);
    letter-spacing: 2px;
  }
  @media #{$size_500} {
    @include fontsize(20);
    letter-spacing: 1px;
  }
}

.part_for-guest_sectionIntro {
  @include fontsize(16);
  @include x03;
  line-height: 1.875;
  padding-bottom: 75px;
  letter-spacing: 2px;

  @media #{$size_1200} {
    padding-bottom: 60px;
    letter-spacing: 1px;
  }
  @media #{$size_1000} {
    padding-bottom: 20px;
  }
  @media #{$size_500} {
    letter-spacing: 0;
    @include fontsize(14);
  }
}

.part_pd-bottom-10 {
  padding-bottom: 10px;
}

.part_display_none_1000 {
  @media #{$size_1000} {
    display: none;
  }
}
